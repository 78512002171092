import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Push Press 3×12`}</p>
    <p>{`DB Pullover 3×12`}</p>
    <p>{`Single Arm Split Jerk 3×6/arm`}</p>
    <p>{`then,`}</p>
    <p>{`21-15-9 reps each of:`}</p>
    <p>{`DB S2OH (50/35’s)`}</p>
    <p>{`CTB Pullups`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      